import React from 'react';

import styled from '@emotion/styled';
import { HavahLogo, TryPCImage } from '@/Assets/Images';
import COLOR from '@/Assets/color';
import FONT from '@/Assets/font';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${COLOR.Gray_9};
  margin: 0;
  min-height: 100vh;
`;

const TryPcImg = styled.img`
  object-fit: cover;
  width: 171px;
  height: 164px;
`;

const DescriptionContainer = styled.div``;

const Title = styled.div`
  margin: 10px 0;
  color: ${COLOR.Primary_Green};
  font-family: ${FONT.koho};
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
`;

const Description = styled.div`
  text-align: center;
  color: ${COLOR.Gray_6};
  font-family: ${FONT.pretendard};
  line-height: 22px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  color: ${COLOR.White};
  font-weight: 700;
  line-height: 22px;
  font-family: ${FONT.koho};
  bottom: 16px;
  gap: 8px;
`;

const HavahLogoImg = styled.img`
  width: 96px;
  height: 36px;
`;

function ErrorMobile() {
  return (
    <Container>
      <TryPcImg src={TryPCImage} />
      <DescriptionContainer>
        <Title>Please try again using PC!</Title>
        <Description>
          Access to HAVAH Testnet Faucet is restricted <br />
          on mobile devices.
        </Description>
      </DescriptionContainer>
      <Footer>
        <HavahLogoImg src={HavahLogo} />
        Faucet
      </Footer>
    </Container>
  );
}

export default ErrorMobile;
