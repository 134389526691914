import React from 'react';
import COLOR from '@/Assets/color';
import styled from '@emotion/styled';
import HeaderSection from '@/Sections/HeaderSection';
import { OopsImage } from '@/Assets/Images';
import FONT from '@/Assets/font';

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: ${COLOR.Gray_9};
`;

const Contents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OopsImg = styled.img`
  width: 265px;
  height: 260px;
`;

const DescriptionContainer = styled.div``;

const Title = styled.div`
  font-family: ${FONT.LuckiestGuy};
  color: ${COLOR.White};
  font-size: 30px;
  line-height: 40px;
`;

const Description = styled.div`
  color: ${COLOR.Mono_3};
  font-size: 18px;
  line-height: 24px;
`;

function Error() {
  return (
    <Container>
      <HeaderSection pageType="error" />
      <Contents>
        <OopsImg src={OopsImage} />
        <DescriptionContainer>
          <Title>OOOPS!</Title>
          <Description>
            HAVAH Testnet Faucet is temporarily unavailable,
            <br />
            please try again later.
          </Description>
        </DescriptionContainer>
      </Contents>
    </Container>
  );
}

export default Error;
