export const DEV_DOMAIN = 'faucet.dev.havah.io';
export const VEGA_DOMAIN = 'faucet.vega.havah.io';
export const ALTAIR_DOMAIN = 'faucet.altair.havah.io';
export const DEV_NETWORK_ID = '0x110';
export const VEGA_NETWORK_ID = '0x101';
export const ALTAIR_NETWORK_ID = '0x111';
export const VEGA_TRACKING_ID = 'G-FN9NR9EWYB';
export const ALTAIR_TRACKING_ID = 'G-RY9Y22JCH4';
export const IS_DEV = window.location.origin.includes(DEV_DOMAIN);
export const IS_VEGA = window.location.origin.includes(VEGA_DOMAIN);
export const IS_ALTAIR = window.location.origin.includes(ALTAIR_DOMAIN);
export const IS_LOCAL = process.env.NODE_ENV === 'development';
export const API_ENDPOINT = `https://${
  // eslint-disable-next-line no-nested-ternary
  IS_VEGA ? VEGA_DOMAIN : IS_ALTAIR ? ALTAIR_DOMAIN : IS_LOCAL ? VEGA_DOMAIN : DEV_DOMAIN
}`;
export const NETWORK_ID = IS_VEGA ? VEGA_NETWORK_ID : IS_ALTAIR ? ALTAIR_NETWORK_ID : IS_LOCAL ? VEGA_NETWORK_ID : DEV_NETWORK_ID;
export const REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID = IS_VEGA ?  VEGA_TRACKING_ID: IS_ALTAIR? ALTAIR_TRACKING_ID : '';