import { atom } from 'recoil';
import { accountStateType } from '@/Constants/type';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const hvhAccountState = atom<accountStateType>({
  key: 'hvhAccountState',
  default: {
    address: '',
  },
  effects: [persistAtom],
});
