import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';

import COLOR from './Assets/color';
import HeaderSection from './Sections/HeaderSection';
import BodySection from './Sections/BodySection';
import { useRecoilValue } from 'recoil';
import {
  IsShowErrorToastAtom,
  IsShowToastAtom,
  ToastContentAtom,
} from './States/state';
import ToastPopupModule from './Modules/ToastPopupModule';
import FONT from './Assets/font';
import packageJson from '../package.json';
import { useNavigate } from 'react-router';
import { REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } from '@/Constants/value'
import ReactGA from 'react-ga';

const Container = styled.div`
  background: ${COLOR.Gray_10};
  width: 100vw;
  height: 100vh;
  min-width: 800px;
  min-height: 900px;
  position: relative;
`;

const FooterSection = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: ${COLOR.Gray_7};
  line-height: 18px;
  bottom: 80px;
  font-family: ${FONT.pretendard};
  font-weight: 400;
`;

const Gap = styled.span`
  margin: 0 12px;
`;

function App() {
  const navigate = useNavigate();
  const isShowToast = useRecoilValue(IsShowToastAtom);
  const isShowErrorToast = useRecoilValue(IsShowErrorToastAtom);
  const toastContent = useRecoilValue(ToastContentAtom);
  const [initialized, setInitialized] = useState(false);

  if (!initialized) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
    script.async = true;

    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}');
    `;

    document.head.appendChild(script);
    document.head.appendChild(gaScript);

    ReactGA.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    setInitialized(true);
  }

  useEffect(() => {
    
    if (window.location.search || window.location.hash) {
      navigate('/', { replace: true });
    }
    if (initialized)
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.hash, window.location.search, initialized]);

  return (
    <Container>
      <HeaderSection pageType="main" />
      <BodySection />
      <FooterSection>
        Copyrights © WEB3 SOLUTIONS Ltd. All rights reserved<Gap>|</Gap>Version{' '}
        {packageJson.version}
      </FooterSection>

      {isShowToast && <ToastPopupModule text={toastContent} type="success" />}
      {isShowErrorToast && (
        <ToastPopupModule text={toastContent} type="error" />
      )}
    </Container>
  );
}

export default App;
