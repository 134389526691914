type RestFulAPIType = {
  domain: string;
  queryParams?: string;
  params?: any;
}

const queryParser = (params: any) => {
  try {
    if (!params) {
      return "";
    }
    let initialValue = "?";
    const keys = Object.keys(params);
    keys.map((el: any, idx) => {
      if (idx === keys.length - 1) {
        initialValue = initialValue + el + "=" + params[el];
        return;
      }
      initialValue = initialValue + el + "=" + params[el] + "&";
    });
    return initialValue;

  } catch (e) {
    throw e
  }
}

const get = async ({
  domain,
  queryParams,
  params
}: RestFulAPIType) => {
  const controller = new AbortController();
  let timerId = setTimeout(() => controller.abort(), 5000);
  try {

    let qs = "";
    if (!!params) {
      qs = queryParser(params);
    }
    const rsp = await fetch(`${domain}${qs}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: 'follow',
      signal: controller.signal,
    });
    if (rsp.ok) {
      const response = await rsp.json();
      const retCode: number = response.retCode ? response.retCode : 0;
      const retMsg: string = response.retMessage ? response.retMessage : "";
      // if (retCode !== 0) {
      //   throw new Error(JSON.stringify({ code: retCode, message: retMsg }));
      // } else 
      return response;
    } else {
      throw new Error(JSON.stringify({ code: rsp.status }));
    }
  } catch (e) {
    throw e;
  } finally {
    clearTimeout(timerId);
  }
}

const post = async ({
  domain,
  params
}: RestFulAPIType) => {
  try {
    const rsp = await fetch(domain, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    });
    if (rsp.ok) {
      const response = await rsp.json();
      const retCode: number = response.retCode ? response.retCode : 0;
      const retMsg: string = response.retMessage ? response.retMessage : "";
      if (retCode !== 0) {
        throw new Error(JSON.stringify({ code: retCode, message: retMsg }));
      } else {
        return response;
      }
    } else {
      throw new Error(JSON.stringify({ code: rsp.status }));
    }
  } catch (e) {
    throw e;
  }
}

export {
  get,
  post
}