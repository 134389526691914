import {
  IsShowErrorToastAtom,
  IsShowToastAtom,
  ToastContentAtom,
} from '@/States/state';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';

export const useShowToast = () => {
  const setIsShowToast = useSetRecoilState(IsShowToastAtom);
  const setIsShowErrorToast = useSetRecoilState(IsShowErrorToastAtom);
  const setToastContent = useSetRecoilState(ToastContentAtom);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [errorTimeoutId, setErrorTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );

  const showToast = (type: 'success' | 'error', content: any) => {
    setToastContent(content);
    setIsShowToast(false);
    setIsShowErrorToast(false);

    setTimeout(() => {
      if (type === 'success') {
        if (timeoutId) {
          clearTimeout(timeoutId);
          setTimeoutId(null);
        }
        setIsShowToast(true);
        const id = setTimeout(() => {
          setIsShowToast(false);
        }, 2000);
        setTimeoutId(id);
      } else {
        if (errorTimeoutId) {
          clearTimeout(errorTimeoutId);
          setErrorTimeoutId(null);
        }
        setIsShowErrorToast(true);
        const id = setTimeout(() => {
          setIsShowErrorToast(false);
        }, 2000);
        setErrorTimeoutId(id);
      }
    }, 100);
  };

  return showToast;
};
