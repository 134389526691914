import { atom } from 'recoil';

export const IsShowToastAtom = atom({
  key: 'IsShowToastAtom',
  default: false,
});

export const IsShowErrorToastAtom = atom({
  key: 'IsShowErrorToastAtom',
  default: false,
});

export const ToastContentAtom = atom({
  key: 'ToastContentAtom',
  default: '',
});

export const IsButtonActive = atom({
  key: 'IsButtonActive',
  default: true,
});
