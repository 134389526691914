/* eslint-disable no-nested-ternary */
import COLOR from '@/Assets/color';
import styled from '@emotion/styled';
import React from 'react';

type UButtonType = 'primary' | 'normal' | 'disabled';
type UButtonTextAlignType = 'portrait' | 'landscape';
type UButtonHeight = 'long' | 'short';

type Props = {
  content: React.ReactNode;
  type: UButtonType;
  clickEvent: () => void;
  textAlign?: UButtonTextAlignType;
  buttonHeightType?: UButtonHeight;
};

const Container = styled.div<{
  type: UButtonType;
  textAlign?: UButtonTextAlignType;
  buttonHeightType?: UButtonHeight;
}>`
  font-family: 'Pretendard';
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-width: 131px;
  max-width: 200px; */
  width: 190px;
  height: 70px;
  /* height: 58px; */
  gap: 20px;
  /* padding: 0px 20px; */
  border-radius: 6px;
  /* border: ${({ type }) =>
    type === 'disabled' ? `1px solid ${COLOR.Disable_Green}` : ''}; */
  background-color: ${({ type }) =>
    type === 'primary'
      ? COLOR.Primary_Green
      : type === 'disabled'
      ? COLOR.Disable_Green
      : ''};
  color: ${({ type }) =>
    type === 'normal'
      ? COLOR.Primary_Green
      : type === 'disabled'
      ? COLOR.White
      : ''};
  cursor: ${({ type }) => (type === 'disabled' ? 'not-allowed' : 'pointer')};
  white-space: pre-wrap;
  text-align: center;

  :hover {
    background-color: ${({ type }) =>
      type === 'primary'
        ? ''
        : type === 'disabled'
        ? COLOR.Disable_Green
        : `rgba(0, 185, 132, 0.16)`};
    color: ${({ type }) =>
      type === 'primary'
        ? ''
        : type === 'normal'
        ? COLOR.Primary_Green
        : COLOR.White};
  }
`;

function ButtonModule({
  content,
  type,
  clickEvent,
  textAlign,
  buttonHeightType,
}: Props) {
  return (
    <Container
      onClick={type === 'disabled' ? undefined : clickEvent}
      type={type}
      textAlign={textAlign}
      buttonHeightType={buttonHeightType}
    >
      {/* {centerEllipsis(content)} */}
      {content}
    </Container>
  );
}

ButtonModule.defaultProps = {
  textAlign: undefined,
  buttonHeightType: undefined,
};

export default ButtonModule;
