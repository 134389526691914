import { useEffect, useState } from 'react';

export const useDetectNetwork = () => {
  const [domainNet, setDomainNet] = useState('Vega');

  useEffect(() => {
    const domain = window.location.hostname;
    if (domain.includes('dev')) {
      setDomainNet('Deneb');
    }
    if (domain.includes('altair')) {
      setDomainNet('Altair');
    }
  }, []);

  return domainNet;
};
