import React from 'react';
import styled from '@emotion/styled';
import { ExclamationIconImage, CheckIconImage } from '@/Assets/Icons';
import COLOR from '@/Assets/color';
import { keyframes } from '@emotion/react';

type UPopupType = 'success' | 'error';

type Props = {
  text: string;
  type: UPopupType;
};

const anima = keyframes`
  0%{
    top: -100px;
  }
  25%{
    top: 16px;
  }
  50%{
    top: 16px;
  }
  75%{
    top: 16px;
  }
  100%{
    top: -100px;
  }
`;

const Container = styled.div<{ type: UPopupType }>`
  position: fixed;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  padding: 0px 20px;
  align-items: center;
  flex-shrink: 0;
  gap: 10px;
  min-height: 60px;
  border-radius: 6px;
  background: ${({ type }) =>
    type === 'success' ? COLOR.Toast_Green : COLOR.Toast_Red};
  color: ${COLOR.Gray_10};
  line-height: 22px;
  font-weight: 500;
  z-index: 20;
  animation: ${anima} 2000ms;
`;

const Text = styled.div`
  min-width: 100px;
  width: 100%;
  /* max-width: 300px; */
  margin: 18px 0;
  /* white-space: pre-wrap; */
  /* word-break: break-all; */
`;

const Icon = styled.img``;

function ToastPopupModule({ text, type }: Props) {
  return (
    <Container type={type}>
      {type === 'success' ? (
        <Icon src={CheckIconImage} />
      ) : (
        <Icon src={ExclamationIconImage} />
      )}
      <Text>{text}</Text>
    </Container>
  );
}

export default ToastPopupModule;
