const connectToHavah = async () => {
  try {
    const rsp = await window.havah.connect();
    return rsp;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

const getAddrfromHAVAH = async () => {
  try {
    const accounts = await window.havah.accounts();
    return accounts;
  } catch (e) {
    // console.log(e);
    return e;
  }
};

const sign = async (data: string) => {
  try {
    const rsp = await window.havah.sign(data);
    return rsp;
  } catch (e) {
    throw e;
  }
};

export { connectToHavah, getAddrfromHAVAH, sign };
