const COLOR = {
  Primary_Green: '#00B984',
  Disable_Green: '#11634B',
  Toast_Green: '#CDF8E2',
  Toast_Red: '#FFD4D1',
  White: '#FFFFFF',
  Gray_10: '#000000',
  Gray_9: '#222222',
  Gray_8: '#8A8A8A',
  Gray_2: '#FAFAFA',
  Gray_6: '#ABABAB',
  Gray_7: '#8A8A8A',
  Mono_3: '#bdc2c4',
};

export default COLOR;
