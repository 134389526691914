import { useState, useEffect, useRef } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { BubblePoint, HavahLogo } from '@/Assets/Images';
import COLOR from '@/Assets/color';
import styled from '@emotion/styled';
import { getAddrfromHAVAH, connectToHavah } from '@/sc/havah';
import centerEllipsis from '@/Utils/centerEllipsis';
import { NETWORK_ID } from '@/Constants/value';
import FONT from '@/Assets/font';
import { useShowToast } from '@/Hooks/useShowToast';
import { accountStateType } from '@/Constants/type';
import { hvhAccountState } from '@/recoil/atom';
import { useDetectNetwork } from '@/Hooks/useDetectNetwork';
import { CloseIconImage } from '@/Assets/Icons';
import { IsButtonActive } from '@/States/state';

type Props = {
  pageType: 'main' | 'error';
};

const Container = styled.div`
  z-index: 10;
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  /* min-width: 800px; */
`;

const ContainerWrapper = styled.div`
  /* max-width: 1980px; */
  min-width: 800px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  height: 90px;
`;

const Right = styled.div`
  margin-left: 32px;
`;

const HomeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 8px;
  height: 58px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.16) inset;

  color: ${COLOR.White};
  cursor: pointer;
  font-family: 'KoHo';
  font-weight: 700;
  font-size: 16px;
  :hover {
    background: rgba(217, 217, 217, 0.1);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.16) inset;
  }
`;

const WhyButtonContainer = styled.div`
  position: relative;
`;

const WhyButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  width: 184px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  box-sizing: border-box;
  color: ${COLOR.White};
  font-family: ${FONT.koho};
  line-height: 22px;
  font-weight: 700;
  cursor: pointer;
  :hover {
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
  }
`;

const WhyBallon = styled.div`
  position: absolute;
  top: 80px;
  left: -157px;
  width: 340px;
  height: 192px;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: ${COLOR.Gray_9};
  color: ${COLOR.Gray_6};
  /* ::after {
    position: absolute;
    top: -8px;
    right: 5px;
    content: '';
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: ${COLOR.Gray_9} transparent;
    display: block;
  }
  ::before {
    position: absolute;
    top: -11px;
    right: 5px;
    content: '';
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: rgba(255, 255, 255, 0.16) transparent;
  } */
`;

const WhyBallonArrow = styled.img`
  width: 22px;
  height: 14px;
  position: absolute;
  top: -14px;
  right: 10px;
`;

const WhyBallonCloseImg = styled.img`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 16px;
  width: 16px;
  height: 16px;
`;

const WhyDescriptionTitle = styled.div`
  color: ${COLOR.White};
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin: 16px 0px 6px 16px;
`;

const WhyDescription = styled.div`
  margin-left: 16px;
  line-height: 22px;
  font-weight: 400;
`;

const LogoImg = styled.img`
  width: 96px;
  height: 36px;
`;

const Left = styled.div`
  display: flex;
  gap: 18px;
  margin-right: 32px;
  font-size: 14px;
`;

const ConnectButton = styled.div<{ wide: boolean; isConnect: boolean }>`
  font-family: ${FONT.koho};
  font-weight: 700;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.Primary_Green};
  height: 58px;
  border-radius: 6px;
  width: ${({ wide, isConnect }) =>
    !isConnect ? '103px' : wide ? '246px' : '131px'};
  border: 1px solid ${COLOR.Primary_Green};
  box-sizing: border-box;
  /* box-shadow: 0 0 0 1px ${COLOR.Primary_Green} inset; */

  :hover {
    color: ${COLOR.Primary_Green};
    background: rgba(0, 185, 132, 0.16);
    border: 2px solid ${COLOR.Primary_Green};
    box-sizing: border-box;
    /* box-shadow: 0 0 0 2px ${COLOR.Primary_Green} inset; */
  }
  transition: 500ms;
  transition-property: width;
`;

const ButtonText = styled.div`
  color: ${COLOR.Primary_Green};
  padding: 0px 20px;
`;

const DisconnectButton = styled.div<{ wide: boolean }>`
  width: ${({ wide }) => (wide ? '109px' : '0px')};
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLOR.Gray_10};
  border-radius: 6px;
  background-color: ${COLOR.Primary_Green};
  margin-right: 6px;
  overflow: hidden;
  transition: 500ms;
`;

function HeaderSection({ pageType }: Props) {
  const [isShowTooltip, setIsShowTooltip] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [isMouseOn, setIsMouseOn] = useState(false);
  const navigate = useNavigate();
  const hvhRef = useRef<accountStateType>();
  const [hvhAccount, setHvhAccount] = useRecoilState(hvhAccountState);
  const setIsButtonActive = useSetRecoilState(IsButtonActive);
  const showToast = useShowToast();
  const domainNet = useDetectNetwork();

  const clickHome = () => {
    if (window.location.pathname === '/') {
      location.reload();
    } else {
      navigate('/');
    }
  };

  const clickConnect = async () => {
    let returns: {
      address: string;
      nid: string;
    };
    if (!window.havah) {
      window.open(
        'https://chrome.google.com/webstore/detail/havah-wallet/cnncmdhjacpkmjmkcafchppbnpnhdmon'
      );
      setIsConnect(false);
    } else {
      if (isConnect) {
        setHvhAccount({ address: '' });
        setIsConnect(false);
        showToast('error', 'Disconnected.');
      } else {
        await connectToHavah().then(async () => {
          returns = await getAddrfromHAVAH();
          if (returns == null || !returns.address) {
            showToast('error', 'Wallet connection failed.');
          }
          if (returns.nid === NETWORK_ID) {
            setHvhAccount(returns);
            setIsConnect(true);
            showToast('success', 'Connected.');
          } else {
            showToast('error', 'Please check the networkID of HAVAH Wallet.');
          }
        });
      }
    }
  };

  useEffect(() => {
    if (!isConnect) {
      setIsButtonActive(true);
    }
  }, [isConnect]);

  const walletLister = async () => {
    window.havah?.on('accountsChanged', (changed: any) => {
      if (!hvhRef?.current?.address) {
        setHvhAccount({ address: '' });
        return;
      }

      if (!changed.address) {
        setIsConnect(false);
        setHvhAccount({ address: '' });
        showToast('error', 'HAVAH Wallet has been locked and disconnected.');
        return;
      }

      if (
        hvhRef?.current?.address &&
        hvhRef?.current?.address !== changed.address
      ) {
        setIsConnect(false);
        setHvhAccount({ address: '' });
        showToast('error', 'Account has been changed and disconnected.');
        return;
      }

      if (hvhRef?.current?.nid && hvhRef?.current?.nid !== changed.nid) {
        setIsConnect(false);
        setHvhAccount({ address: '' });
        showToast('error', 'NetworkID has been changed and disconnected.');
        return;
      }
    });
  };

  useEffect(() => {
    if (hvhAccount.address) {
      setIsConnect(true);
    }
    setTimeout(() => {
      walletLister();
    }, 100);
  }, []);

  const overMouse = () => {
    setIsMouseOn(true);
  };

  const leaveMouse = () => {
    setIsMouseOn(false);
  };

  const showTooltip = () => {
    setIsShowTooltip(!isShowTooltip);
  };

  useEffect(() => {
    hvhRef.current = hvhAccount;
    if (hvhAccount.address === '') {
      setIsConnect(false);
    }
  }, [hvhAccount]);

  return (
    <Container>
      <ContainerWrapper>
        <Right>
          <HomeBtn onClick={clickHome}>
            <LogoImg src={HavahLogo} />
            {domainNet} Faucet
          </HomeBtn>
        </Right>
        {pageType === 'main' && (
          <Left>
            <WhyButtonContainer>
              <WhyButton onClick={showTooltip}>HAVAH testnet faucet?</WhyButton>
              {isShowTooltip && (
                <WhyBallon>
                  <WhyBallonArrow src={BubblePoint} />
                  <WhyBallonCloseImg
                    onClick={showTooltip}
                    src={CloseIconImage}
                  />
                  <WhyDescriptionTitle>
                    HAVAH testnet faucet?
                  </WhyDescriptionTitle>
                  <WhyDescription>
                    HAVAH testnet faucet is a tool to supply testnet HVH coins
                    to developers for the purpose of developing and testing
                    their decentralized applications (DApps). If faucet's
                    balance is insufficient or the number of attempts exceeds
                    the limit, you may not be able to receive HVH.
                  </WhyDescription>
                </WhyBallon>
              )}
            </WhyButtonContainer>
            <ConnectButton
              isConnect={isConnect}
              wide={isMouseOn && isConnect}
              onMouseOver={overMouse}
              onMouseLeave={leaveMouse}
              onClick={isConnect ? undefined : clickConnect}
            >
              <ButtonText>
                {!isConnect ? `Connect` : centerEllipsis(hvhAccount.address)}
              </ButtonText>
              {isMouseOn && isConnect && (
                <DisconnectButton wide={isMouseOn} onClick={clickConnect}>
                  Disconnect
                </DisconnectButton>
              )}
            </ConnectButton>
          </Left>
        )}
      </ContainerWrapper>
    </Container>
  );
}

export default HeaderSection;
