const centerEllipsis = (str: string) => {
  if (str === undefined) {
    return;
  }
  if (str.length > 15) {
    return `${str.substring(0, 5)}...${str.substring(
      str.length - 5,
      str.length
    )}`;
  }
  return str;
};

export default centerEllipsis;
