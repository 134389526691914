import React from 'react';
import isMobile from 'is-mobile';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import App from './App';
import Error from './Pages/Error';
import ErrorMobile from './Pages/ErrorMobile';

const router = isMobile()
  ? createBrowserRouter([
      {
        path: '/',
        element: <ErrorMobile />,
        errorElement: <ErrorMobile />,
      },
    ])
  : createBrowserRouter([
      {
        path: '/',
        element: <App />,
        errorElement: <Error />,
      },
      {
        path: '*',
        element: <Navigate to="/" replace />,
      },
    ]);

export default router;
