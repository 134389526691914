import React, { useEffect, useState, useCallback } from 'react';
import { useRecoilState } from 'recoil';

import styled from '@emotion/styled';
import { FaucetIconImage } from '@/Assets/Icons';
import COLOR from '@/Assets/color';
import ButtonModule from '@/Modules/ButtonModule';
import { AnimationHole, FaucetBg, TakeAnimationGif } from '@/Assets/Images';
import { API_ENDPOINT } from '@/Constants/value';
import { sign } from '@/sc/havah';
import { post } from '@/sc/rest';
import { useShowToast } from '@/Hooks/useShowToast';
import { accountStateType } from '@/Constants/type';
import { hvhAccountState } from '@/recoil/atom';
import FONT from '@/Assets/font';
import { useDetectNetwork } from '@/Hooks/useDetectNetwork';
import { IsButtonActive } from '@/States/state';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-top: 90px;
`;

const Bg = styled.div`
  position: absolute;
  inset: 0;
  z-index: 0;
`;

const BackgroundImg = styled.img`
  opacity: 0.5;
  width: 100%;
  min-width: 800px;
  height: 1080px;
  position: absolute;
  top: -230px;
`;

const FaucetIcon = styled.img`
  margin-top: 100px;
  width: 58px;
  height: 58px;
  z-index: 5;
`;

const Title = styled.div`
  color: ${COLOR.Gray_2};
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  margin-top: 24px;
  font-family: 'KoHo';
  font-weight: 700;
  z-index: 5;
`;

const Description = styled.div`
  text-align: center;
  color: ${COLOR.Gray_6};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 12px;
  height: 44px;
  z-index: 5;
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  z-index: 10;
  /* z-index: 5; */
`;

const AnimationWrapper = styled.div<{ isShow: boolean }>`
  position: relative;
  top: -80px;
  z-index: ${({ isShow }) => (isShow ? 20 : 5)};
`;

const TakeAnimationImg = styled.img`
  height: 401.2px;
  width: 272px;
`;

const InnerText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: ${COLOR.Gray_10};
`;

const InnerBtnText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  font-family: ${FONT.pretendard};
`;

function BodySection() {
  const [buttonType, setIsButtonActive] = useRecoilState(IsButtonActive);
  const [isShow, setIsShow] = useState(false);
  const [hvhAccount, setHvhAccount] = useRecoilState(hvhAccountState);
  // const [isDisabled, setIsDisabled] = useState(false);
  const showToast = useShowToast();
  const domainNet = useDetectNetwork();

  const clickEvent = useCallback(async () => {
    if (!window.havah) {
      showToast('error', 'Please connect your HAVAH Wallet.');
      return;
    }
    const res = await window.havah.accounts();
    if (!res.address) {
      setHvhAccount({ address: '' });
      showToast('error', 'Please connect your HAVAH Wallet.');
      return;
    }
    if (hvhAccount.address === '') {
      showToast('error', 'Please connect your HAVAH Wallet.');
      return;
    }
    if (!isShow) {
      const date = Math.floor(+new Date() / 1000);
      const ret = await requestSign(date + '|' + hvhAccount.address);

      const params = {
        timestamp: date,
        address: hvhAccount.address,
        sig: ret.signData.signature,
      };

      if (ret.type === 'success') {
        requestMoney(params);
      }
    }
  }, [hvhAccount, showToast]);

  const requestSign = useCallback(async (data: string) => {
    if (window.havah) {
      const signData = await sign(data);
      return signData;
    }
  }, []);

  const requestMoney = useCallback(
    async (params: any) => {
      try {
        const uri = API_ENDPOINT + '/v2/showmethemoney';
        try {
          const rsp = await post({
            domain: uri,
            params,
          });

          setIsShow(true);
          setIsButtonActive(false);
          setTimeout(() => {
            setIsShow(false);
            setIsButtonActive(true);
          }, 3000);

          showToast('success', rsp.retMessage);
          return rsp.result;
        } catch (e) {
          throw e;
        }
      } catch (e: any) {
        try {
          const _parsed = JSON.parse(e.message);
          if (_parsed.code === 101) {
            setIsButtonActive(false);
          }
          showToast('error', _parsed.message);
        } catch (e) {
          showToast('error', 'Temporary error occurs. Please retry it later.');
        }
        return '';
      }
    },
    [showToast]
  );

  return (
    <Container>
      <FaucetIcon src={FaucetIconImage} />
      <Title>HAVAH {domainNet} Faucet</Title>
      <Description>
        {`Please make sure to switch the network of HAVAH Wallet to ${domainNet} and connect it.`}
      </Description>
      <ButtonContainer>
        <ButtonModule
          content={<InnerText>Send Me HVH</InnerText>}
          type={buttonType ? 'primary' : 'disabled'}
          clickEvent={clickEvent}
        />
      </ButtonContainer>
      <AnimationWrapper isShow={isShow}>
        <TakeAnimationImg src={isShow ? TakeAnimationGif : AnimationHole} />
      </AnimationWrapper>

      <Bg>
        <BackgroundImg src={FaucetBg} />
      </Bg>
    </Container>
  );
}

export default BodySection;
